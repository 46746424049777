/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpResponse } from '@angular/common/http';
import { Md5 } from 'ts-md5';

export function isUndefined(value: any): boolean {
  return typeof value === 'undefined';
}

export function isNull(value: any): boolean {
  return value === null;
}

export function isFunction(value: any): boolean {
  return typeof value === 'function';
}

export function isNumber(value: any): value is number {
  return typeof value === 'number';
}

export function isString(value: any): value is string {
  return typeof value === 'string';
}

export function isBoolean(value: any): value is boolean {
  return typeof value === 'boolean';
}

export function isObject(value: any): boolean {
  return value !== null && typeof value === 'object';
}

export function isNumberFinite(value: any): boolean {
  return isNumber(value) && isFinite(value);
}

export function isVowel(letter: string): boolean {
  const vowels = ['a', 'e', 'i', 'o', 'u'];

  return vowels.indexOf(letter) !== -1;
}

export function applyPrecision(num: number, precision: number): number {
  if (precision <= 0) {
    return Math.round(num);
  }

  const tho = 10 ** precision;

  return Math.round(num * tho) / tho;
}

export function extractDeepPropertyByMapKey(obj: any, map: string): any {
  const keys = map.split('.');
  const head = keys.shift();

  return keys.reduce(
    (prop: any, key: string) => {
      return !isUndefined(prop) && !isNull(prop) && !isUndefined(prop[key]) ? prop[key] : undefined;
    },
    obj[head || ''],
  );
}

export function extractDeepPropertyByParentMapKey(obj: any, map: string): any {
  const keys = map.split('.');
  const tail = keys.pop();
  const props = extractDeepPropertyByMapKey(obj, keys.join('.'));

  return { props, tail };
}

export function getKeysTwoObjects(obj: any, other: any): any {
  return [...Object.keys(obj), ...Object.keys(other)].filter((key, index, array) => array.indexOf(key) === index);
}

export function isDeepEqual(obj: any, other: any): any {
  if (!isObject(obj) || !isObject(other)) {
    return obj === other;
  }

  return getKeysTwoObjects(obj, other).every((key: any): boolean => {
    if (!isObject(obj[key]) && !isObject(other[key])) {
      return obj[key] === other[key];
    }
    if (!isObject(obj[key]) || !isObject(other[key])) {
      return false;
    }

    return isDeepEqual(obj[key], other[key]);
  });
}

export function deepCopy<T>(value: T): T {
  if (typeof structuredClone === 'function') {
    try {
      return structuredClone(value);
    } catch (error) {
      // just in case this still throws an error fall back to the old method
      return JSON.parse(JSON.stringify(value));
    }
  } else {
    return JSON.parse(JSON.stringify(value));
  }
}

/**
 * Makes object that doesn't have any `null` or `undefined` or empty arrays.
 *
 * @param obj
 * @param deleteEmptyArray if set to false, will not remove empty arrays --> `[]`
 */
export function makeObjectWithNoNullValues(obj: any, deleteEmptyArray = true): Record<string, any> {
  const params: Record<string, any> = {};

  Object.keys(obj).forEach(key => {
    if (
      obj[key] == null ||
      obj[key] === undefined ||
      (deleteEmptyArray && Array.isArray(obj[key]) && !obj[key].length)
    ) {
      return;
    } else {
      params[key] = obj[key];
    }
  });

  return params;
}

/**
 * Returns Enum key when enum value provided
 * @param obj Enum
 * @param value string Enum value
 * @returns string Enum key
 */
export function getEnumKey(obj: {}, value: string): string {
  const keyIndex = Object.values(obj).indexOf(value);

  return Object.keys(obj)[keyIndex];
}

/**
 * Create an anchor element and initiate a file download.
 *
 * @param data
 * @param title needs to end with an extension
 * @param mimeType example: 'text/csv'
 */
export function downloadFile(data: any, title: string, mimeType: string = 'text'): void {
  // Create anchor element
  const a = window.document.createElement('a');
  // Set data object as download value
  a.href = window.URL.createObjectURL(new Blob([data], { type: mimeType }));
  // Set title
  a.download = title;
  // Append anchor to body.
  document.body.appendChild(a);
  a.click();
  // Remove anchor from body
  document.body.removeChild(a);
}

export function md5Hash(code: string = new Date().toString()): string {
  return Md5.hashStr(code);
}

export class MapWithDefault<K, V> extends Map<K, V> {
  constructor(defaultValue: V, entries?: readonly (readonly [K, V])[]);
  constructor(
    private defaultValue: V,
    entries?: Iterable<readonly [K, V]>,
  ) {
    super(entries);
  }

  /**
   * Get a value from the map. If the values doesn't exist it will return the default value.
   *
   * @param key
   */
  override get(key: K): V {
    if (this.has(key)) {
      return super.get(key) as V;
    }

    return this.defaultValue;
  }
}

export function isGearSubdomain(hostname: string | undefined): boolean {
  if (!hostname) return false;

  return hostname.includes('gear');
}

export interface TimeDifference {
  days: number | null;
  hours: number | null;
  minutes: number | null;
  seconds: number | null;
}

export function getTimeDifference(startDate: string, endDate: string): string | null {
  const startDateTime = new Date(startDate).getTime();

  const endDateTime = new Date(endDate).getTime();

  const differenceInMiliseconds = endDateTime - startDateTime;

  const total_seconds = Math.floor(differenceInMiliseconds / 1000);
  const total_minutes = Math.floor(total_seconds / 60);
  const total_hours = Math.floor(total_minutes / 60);
  const days = Math.floor(total_hours / 24);

  const seconds = total_seconds % 60;
  const minutes = total_minutes % 60;
  const hours = total_hours % 24;

  // const timeDifference = {
  //   days: days ? days : null,
  //   hours: hours ? hours : null,
  //   minutes: minutes ? minutes : null,
  //   seconds: seconds ? seconds : null,
  // };

  return days
    ? days + ' days'
    : hours
      ? hours + ' hours'
      : minutes
        ? minutes + ' minutes'
        : seconds
          ? seconds + ' seconds'
          : null;
}

export function downloadFileByUsingResponseHeaders<T extends HttpResponse<unknown>>(
  res: T,
  defaultFileName: string,
): void {
  const contentDisposition = res.headers.get('content-disposition');
  let fileName = contentDisposition ? contentDisposition.split('=')[1] : defaultFileName;

  // handle possible problems
  fileName = fileName.replace(new RegExp('"', 'g'), ''); // remove quotes from the string
  fileName = fileName.replace(new RegExp('_(?=[^.]*$)', ''), ''); // remove any possible underscore at the end of a string

  downloadFile(res.body, fileName, res.headers.get('content-type') ?? 'application/pdf');
}
